.price-page {
  padding: 80px 0 60px;

  @media (min-width: $breakpoint-md) {
    padding: 140px 0 195px;
  }

  .heading {
    margin: 0 auto 40px;
    text-align: center;

    @media (min-width: $breakpoint-md) {
      max-width: 850px;
      margin-bottom: 80px;
    }
  }
}

.table-box {
  @media (min-width: $breakpoint-md) {
    overflow-x: auto;
    overflow-y: hidden;
  }
}

.table {
  @media (min-width: $breakpoint-md) {
    min-width: 980px;
  }
}

.table-top,
.table-row,
.table-footer-row {
  @media (min-width: $breakpoint-md) {
    display: grid;
    grid-template-columns: minmax(0, 388px) minmax(0, 1fr);
  }

  @media (min-width: $breakpoint-lg) {
    grid-template-columns: minmax(0, 420px) minmax(0, 1fr);
  }
}

.table-title {
  text-align: center;
  margin-bottom: 25px;

  @media (min-width: $breakpoint-md) {
    margin-bottom: 0;
    text-align: left;
    display: flex;
    align-items: center;
  }
}

.table-head {
  margin-bottom: 20px;

  @media (min-width: $breakpoint-md) {
    margin-bottom: 0;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  &__cell {
    background-color: $primary;
    color: $white;
    display: flex;
    align-items: center;
    padding: 10px 20px;

    @media (min-width: $breakpoint-md) {
      align-items: stretch;
      flex-direction: column;
      justify-content: center;
      border-top: 1px solid $gray-4;
      height: 90px;
      padding: 5px;
    }

    @media (min-width: $breakpoint-lg) {
      height: 118px;
      padding: 19px;
    }

    &:first-child {
      border-radius: 4px 4px 0 0;
      padding-bottom: 0;
      justify-content: space-between;

      @media (min-width: $breakpoint-md) {
        justify-content: center;
        border-left: 1px solid $gray-4;
        border-radius: 18px 0 0 0;
        text-align: center;
        padding-bottom: 5px;
      }
    }

    &:last-child {
      border-radius: 0 0 4px 4px;
      padding: 0 20px 20px;

      @media (min-width: $breakpoint-md) {
        border-right: 1px solid $gray-4;
        border-radius: 0 18px 0 0;
        padding: 5px;
      }
    }
  }

  &__desc {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;

    strong {
      @media (min-width: $breakpoint-md) {
        font-size: 24px;
      }
    }

    span {
      @media (min-width: $breakpoint-md) {
        vertical-align: text-top;
      }
    }
  }

  &__price {
    font-size: 36px;
    font-weight: 700;

    span {
      font-size: 11px;
      font-weight: 400;
    }
  }

  &__caption {
    font-size: 11px;
    line-height: 15px;
    text-align: center;

    @media (min-width: $breakpoint-md) {
      position: relative;
      text-align: left;
    }

    &:before {
      @media (min-width: $breakpoint-md) {
        content: "";
        position: absolute;
        left: -1px;
        top: 20px;
        bottom: 20px;
        width: 1px;
        background-color: $white;
      }
    }
  }
}

.table-price {
  @media (min-width: $breakpoint-md) {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  &__cell {
    padding: 20px;
    background-color: $white;
    box-shadow: -5px -5px 20px $white, 5px 5px 20px rgba(174, 174, 192, 0.5);
    border-radius: 4px;
    text-align: center;
    margin-bottom: 20px;

    @media (min-width: $breakpoint-md) {
      box-shadow: none;
      background-color: none;
      border-radius: 0;
      margin-bottom: 0;
      height: 118px;
      padding: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border: solid $gray-4;
      border-width: 0 2px 0 0;
    }

    &:first-child {
      @media (min-width: $breakpoint-md) {
        border-width: 0 2px 0;
      }
    }
  }

  &__title {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 8px;
    color: $primary;

    @media (min-width: $breakpoint-md) {
      color: $secondary;
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 14px;
    }
  }

  &__value {
    font-size: 24px;
    margin-bottom: 20px;

    @media (min-width: $breakpoint-md) {
      margin-bottom: 0;
      font-size: 36px;
    }
  }

  &__caption {
    border-top: 1px solid $gray-6;
    padding: 10px 0 20px;
    font-size: 12px;
    color: $gray-5;

    @media (min-width: $breakpoint-md) {
      display: none;
    }
  }

  &__subtitle {
    font-size: 12px;
    color: $gray-5;
    margin-bottom: 10px;
  }
}

.table-row {
  @media (min-width: $breakpoint-md) {
    grid-template-rows: minmax(50px, auto);
  }

  @media (min-width: $breakpoint-lg) {
    grid-template-rows: minmax(80px, auto);
  }

  .table-cell {
    border: solid $gray-4;
    border-width: 2px 0 0 2px;
    padding: 5px 5px 5px 30px;
    display: flex;
    align-items: center;
  }

  &:first-child {
    .table-cell {
      border-radius: 4px 0 0 0;
    }
  }

  &:last-child {
    .table-cell {
      border-width: 2px 0 2px 2px;
      border-radius: 0 0 0 4px;
    }

    .table-subcell {
      border-bottom-width: 2px;

      &:first-child {
        border-bottom-width: 2px;
      }
    }
  }
}

.table-subrow {
  @media (min-width: $breakpoint-md) {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .table-subcell {
    text-align: center;
    font-weight: 600;
    font-size: 24px;
    border: solid $gray-4;
    border-width: 2px 2px 0 0;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.active {
      background-color: rgba($gray-4, 0.2);
    }

    &:first-child {
      border-width: 2px 2px 0;
    }
  }
}

.table-footer-row {
  display: none;

  @media (min-width: $breakpoint-md) {
    display: grid;
  }
}

.table-footer-subrow {
  @media (min-width: $breakpoint-md) {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .table-footer-subcell {
    border: solid $gray-4;
    text-align: center;
    border-width: 0 2px 2px 0;
    padding: 10px 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.active {
      background-color: rgba($gray-4, 0.2);
    }

    &:first-child {
      border-width: 0 2px 2px;
      border-radius: 0 0 0 4px;
    }

    &:last-child {
      border-radius: 0 0 4px 0;
    }
  }
}

.table-footer__title {
  font-size: 12px;
  color: $gray-5;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.table-btn {
  font-size: 12px;
  width: 100%;
  line-height: 40px;
  text-transform: uppercase;

  &.btn {
    padding: 15px;
  }
}

.table-mobile {
  border: 1px solid $gray-6;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 20px;

  &__head {
    background-color: rgba($gray-6, 0.4);
    border-bottom: 1px solid rgba($gray-6, 0.5);
    padding: 14px 20px;
    font-size: 18px;
    font-weight: 600;
  }

  &__row {
    border-bottom: 1px solid rgba($gray-6, 0.5);
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:last-child {
      border-bottom: 0;
    }

    i {
      font-size: 18px;
      padding-left: 10px;
    }
  }
}
