@import '../variables';

.line {
  position: absolute;
  background-color: rgba($secondary, 0.1);

  &:not(.main-line) {
    display: none;

    @media (min-width: $breakpoint-md) {
      display: block;
    }
  }

  &:after {
    content: '';
    position: absolute;
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: $primary;
  }

  &.vertical {
    width: 1px;

    &:after {
      left: 50%;
      transform: translateX(-50%);
      top: calc(100% - 5px);
    }
  }

  &.horizontal {
    height: 1px;
  }
}
