@import '../variables';

.form-section {
  background-color: $primary;
  padding: 40px 0 70px;

  @media (min-width: $breakpoint-sm) {
    background-color: $white;
    padding: 0 0 20px;
  }

  .wrapper {
    background-color: $primary;

    @media (min-width: $breakpoint-sm) {
      padding: 60px 0 100px;
    }
  }

  @media (max-width: $breakpoint-sm - 1) {
  }
  .input-box {
    margin-bottom: 40px;

    &.error, &.input-success {
      margin-bottom: 0;

      span {
        height: 40px;
      }
    }
  }
}

.container {
  position: relative;
}

.line-3 {
  left: 50%;
  transform: translateX(-50%);
  height: 126px;
  top: 100%;
}

.form-box {
  margin: 0 auto;
  max-width: 280px;

  @media (min-width: $breakpoint-sm) {
    margin: -104px 0 0;
    max-width: 100%;
    background-color: $white;
    border-radius: 4px;
    box-shadow: 0px 5px 15px rgba($black, 0.25);
    padding: 30px 20px 10px 30px;
  }
}

.form-title {
  font-size: 50px;
  line-height: 62px;
  color: $white;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;

  @media (min-width: $breakpoint-sm) {
    text-align: left;
  }
}

.form-subtitle {
  font-size: 20px;
  line-height: 30px;
  color: $white;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  margin: 0 -10px 20px;

  @media (min-width: $breakpoint-sm) {
    text-align: left;
    font-size: 24px;
    line-height: 36px;
    margin: 0 0 35px;
  }
}

.form-capture {
  color: $white;
  margin-bottom: 40px;
  text-align: center;

  @media (min-width: $breakpoint-sm) {
    text-align: left;
    color: $gray;
    max-width: 344px;
    margin-bottom: 30px;
  }
}

.form-row {
  @media (min-width: $breakpoint-sm) {
    display: flex;
    margin: 0 -10px;
  }

  .form-input__box {
    @media (min-width: $breakpoint-sm) {
      flex: 1 1 0;
      padding: 0 10px;
    }
  }

  .btn-box {
    @media (min-width: $breakpoint-sm) {
      flex-shrink: 0;
      padding: 0 10px;
      min-width: 228px;
    }

    .btn {
      width: 100%;
    }
  }

  .form-control {
    @media (max-width: $breakpoint-sm - 1) {
      background-color: rgba($white, 0.8);
    }
  }
}
