@import "../variables";

.about-section {
  padding: 80px 0;

  .title-box {
    text-align: center;
    position: relative;
    line-height: normal;
  }

  .hero-title {
    text-align: center;
    margin: 0 auto;
  }

  .hero-title--accent {
    justify-content: center;
  }

  .hero-link-box {
    text-align: center;
    padding: 54px 0 80px;
  }

  .hero-link {
    &:after {
      display: none;
    }
  }

  .container {
    @media (min-width: $breakpoint-xl) {
      max-width: 1486px;
    }
  }
}

.about-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -10px;
}

.about-col {
  padding: 10px;
  display: flex;
  width: 100%;
  max-width: 400px;

  @media (min-width: $breakpoint-sm) {
    max-width: 472px;
    width: 50%;
  }

  @media (min-width: $breakpoint-md) {
    width: 33.33%;
  }
}

.about-card {
  width: 100%;
  background-color: $white;
  box-shadow: 5px 5px 20px rgba(174, 174, 192, 0.5);
  border-radius: 4px;
  text-align: center;
  border: 2px solid $white;
  transition: border-color 0.3s ease;
  min-height: 200px;
  padding: 20px;

  @media (min-width: $breakpoint-sm) {
    padding: 40px 15px;
    min-height: 264px;
  }

  &:hover {
    border-color: $primary;
    transition: border-color 0.3s ease;

    .about-card__icon {
      color: $primary;
      transition: color 0.3s ease;
    }
  }

  &__icon {
    display: inline-block;
    transition: color 0.3s ease;
    font-size: 50px;
    margin-bottom: 30px;

    @media (min-width: $breakpoint-sm) {
      margin-bottom: 40px;
      font-size: 72px;
    }
  }

  &__text {
    max-width: 332px;
    margin: 0 auto;
    font-size: 18px;
    color: $gray;
    line-height: 24px;
  }
}

.typography {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba($gray, 0.1);
  font-size: 14px;
  line-height: 19px;
  color: $gray;

  &-content {
    padding-left: 28px;
  }

  &-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: $secondary;
    text-transform: uppercase;
    position: relative;
    padding-left: 28px;
    margin-bottom: 20px;

    &:before {
      content: "";
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $secondary;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      transition: all 0.3s;
    }
  }

  &-list {
    li {
      margin-bottom: 20px;
      display: flex;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__icon {
      width: 16px;
      height: 16px;
      font-size: 16px;
      flex-shrink: 0;
      color: $black-1;
    }

    &__text {
      padding-left: 20px;
    }
  }

  &-mark {
    margin-bottom: 20px;

    li {
      padding-left: 36px;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        left: 12px;
        top: 7px;
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: $secondary;
      }
    }
  }

  p {
    margin-bottom: 20px;
  }

  a {
    color: $primary;
    font-weight: 600;
    text-decoration: underline;
  }

  b {
    font-weight: 600;
  }
}
