@import '../variables';

.info-section {
  position: relative;
  padding: 30px 0 40px;

  @media (min-width: $breakpoint-sm) {
    padding: 80px 0;
  }

  @media (min-width: $breakpoint-md) {
    padding: 144px 0 76px;
  }

  .heading-accent {
    display: inline-block;

    @media (min-width: $breakpoint-sm) {
      display: block;
    }
  }
}

.info-grid {
  display: grid;
  grid-gap: 10px;
  grid-template-rows: repeat(2, minmax(0, 1fr));
  grid-template-columns: repeat(2, minmax(0, 1fr));
  max-width: 560px;
  margin: 0 auto;

  @media (min-width: $breakpoint-md) {
    grid-gap: 20px;
    grid-template-rows: minmax(0, 1fr);
    grid-template-columns: repeat(4, minmax(0, 1fr));
    max-width: none;
  }
}

.info-row {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);

  @media (min-width: $breakpoint-sm) {
    grid-template-rows: repeat(2, minmax(0, 1fr));
    grid-gap: 20px;
  }
}

.info-col {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);

  &.xs-none {
    display: none;

    @media (min-width: $breakpoint-sm) {
      display: grid;
    }
  }
}

.info-card {
  background-color: $primary;
  border-radius: 4px;
  color: $white;
  position: relative;
  z-index: 0;
  padding: 10px;

  @media (min-width: $breakpoint-sm) {
    padding: 26px 20px 10px 22px;
  }

  &:not(.img-bg) {
    z-index: 1;

    &:after {
      @media (min-width: $breakpoint-md) {
        content: '';
        position: absolute;
        top: calc(100% - 1px);
        left: 50%;
        transform: translateX(-50%);
        border: 45px solid transparent;
        border-top: 40px solid $primary;
      }
    }

    &.card--top {
      &:after {
        @media (min-width: $breakpoint-md) {
          border: 45px solid transparent;
          border-bottom: 40px solid $primary;
          top: auto;
          bottom: calc(100% - 1px);
        }
      }
    }
  }

  &__head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    @media (min-width: $breakpoint-sm) {
      margin-bottom: 25px;
    }
  }

  &__icon {
    font-size: 20px;

    @media (min-width: $breakpoint-sm) {
      font-size: 40px;
    }
  }

  &__count {
    font-size: 14px;
    line-height: 14px;
    font-weight: 700;
    position: relative;

    @media (min-width: $breakpoint-sm) {
      line-height: normal;
      font-size: 24px;
    }

    &:after {
      content: '';
      position: absolute;
      display: inline-block;
      bottom: 0;
      right: 0;
      background-color: $white;
      height: 3px;
      width: 46px;

      @media (min-width: $breakpoint-sm) {
        height: 5px;
        width: 80px;
      }
    }
  }

  &__title {
    font-weight: 700;
    font-size: 10px;
    line-height: 18px;

    @media (min-width: $breakpoint-sm) {
      font-size: 24px;
      line-height: 44px;
    }
  }

  &__text {
    font-size: 10px;
    background-image: 18px;

    @media (min-width: $breakpoint-sm) {
      font-size: 14px;
      line-height: 25px;
    }
  }
}

.img-bg {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
