$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../../assets/fonts/icon-font" !default;

$icon-location-arrow: "\e91a";
$icon-location-pin: "\e91b";
$icon-user: "\e91c";
$icon-check: "\e919";
$icon-minus: "\e912";
$icon-plus1: "\e918";
$icon-flag: "\e917";
$icon-ftp: "\e913";
$icon-hr: "\e914";
$icon-shuttle: "\e915";
$icon-web: "\e916";
$icon-plus: "\e911";
$icon-Group-path1: "\e90f";
$icon-Group-path2: "\e910";
$icon-burger: "\e907";
$icon-login: "\e90d";
$icon-register: "\e90e";
$icon-link: "\e906";
$icon-linkedin: "\e90c";
$icon-check-outline: "\e900";
$icon-employee: "\e901";
$icon-exit-full-screen: "\e902";
$icon-facebook: "\e903";
$icon-folder: "\e904";
$icon-headphones: "\e905";
$icon-organization: "\e908";
$icon-question: "\e909";
$icon-settings: "\e90a";
$icon-twitter: "\e90b";
