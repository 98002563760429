@import '../variables';

.header {
  background-color: $white;
  box-shadow: 0px 1px 15px rgba($black, 0.15);
  position: relative;
}

.header-nav {
  max-width: 1710px;
  height: 60px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: $breakpoint-md) {
    height: 80px;
  }
}

.header-logo {
  width: 260px;
  margin-left: 25px;

  @media (min-width: $breakpoint-md) {
    margin-left: 20px;
  }
}

.logo {
  display: block;
  width: 87px;

  @media (min-width: $breakpoint-md) {
    width: 125px;
  }
}

.header-menu-box {
  position: relative;
  order: 1;
  margin: 0 10px;

  @media (min-width: $breakpoint-md) {
    order: 0;
    margin: 0;
  }
}

.header-menu-opener {
  font-size: 18px;
  color: $primary;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;

  @media (min-width: $breakpoint-md) {
    display: none;
  }
}

.header-menu {
  @media (max-width: $breakpoint-md - 1) {
    background-color: $white;
    border-radius: 2px;
    position: relative;
    box-shadow: 0px 4px 4px rgba($black, 0.25),
      -2px -1px 8px 1px rgba($black, 0.05);
    position: absolute;
    top: calc(100% + 6px);
    right: -15px;
    min-width: 180px;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    transition: opacity 0.3s ease;

    &.active {
      opacity: 1;
      visibility: visible;
      z-index: 10;
      transition: opacity 0.3s ease;
    }

    &:before {
      content: '';
      position: absolute;
      bottom: calc(100% - 1px);
      right: 18px;
      border: 6px solid transparent;
      border-bottom: 6px solid $white;
    }
  }

  @media (min-width: $breakpoint-md) {
    display: flex;
  }

  > li {
    padding: 0 20px;
    line-height: 35px;
    border-bottom: 0.2px solid #d8d8d8;

    @media (min-width: $breakpoint-md) {
      padding: 0 15px;
      border: 0;
      line-height: normal;
    }

    &:last-child {
      border: 0;
    }
  }

  a {
    font-size: 14px;
    line-height: 19px;
    display: inline-block;
    padding: 0 3px;
    position: relative;
    text-decoration: none;
    color: $gray;

    @media (min-width: $breakpoint-md) {
      color: $secondary;
      font-weight: 700;
    }

    span {
      position: relative;
      z-index: 1;
    }

    &:after {
      content: '';
      position: absolute;
      height: 11px;
      bottom: -3px;
      right: 0;
      left: 0;
      background-color: rgba($primary, 0.4);
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    &:hover {
      font-weight: 700;
      color: $primary;

      @media (min-width: $breakpoint-md) {
        color: inherit;

        &:after {
          opacity: 1;
          transition: opacity 0.3s ease;
        }
      }
    }
  }
}

.header-auth {
  display: flex;
  align-items: center;
  margin-left: auto;

  @media (min-width: $breakpoint-md) {
    margin: 0;
    justify-content: flex-end;
    width: 260px;
  }

  > li {
    padding: 0 10px;

    @media (min-width: $breakpoint-md) {
      padding: 0 20px;
    }
  }

  &__btn {
    @media (max-width: $breakpoint-md - 1) {
      background-color: transparent;
      color: $secondary;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;

      span {
        display: none;
      }

      i {
        display: inline-block;
      }
    }

    i {
      @media (min-width: $breakpoint-md) {
        display: none;
      }
    }

    &--register {
      @media (min-width: $breakpoint-md) {
        font-size: 14px;
        line-height: 19px;
        font-weight: 700;
        text-decoration: none;
        color: $secondary;
      }
    }

    &--login {
      @media (min-width: $breakpoint-md) {
        display: inline-block;
        font-weight: 700;
        color: $white;
        text-align: center;
        min-width: 114px;
        padding: 5px;
        transition: all 0.3s ease;
        cursor: pointer;
        border-radius: 4px;
        text-decoration: none;
        font-size: 14px;
        line-height: 26px;
        background-color: $primary;

        &:hover {
          background-color: #519ec8;
          color: $white;
        }
      }
    }
  }
}
