*,
*::before,
*::after {
  box-sizing: border-box;
  outline: none;
}

body {
  margin: 0;
  height: auto;
}

img,
video,
canvas,
audio,
iframe,
embed,
object {
  vertical-align: middle;
  max-width: 100%;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

address {
  font-style: normal;
}

input[type='text'],
input[type='email'],
input[type='search'],
input[type='password'],
input[type='number'] {
  outline: 0;
  -webkit-appearance: none;
}

button {
  border: 0;
  cursor: pointer;
  padding: 0;
  background-color: transparent;
}

textarea {
  resize: vertical;
  -webkit-appearance: none;
}
