@import "../variables";

.faq-page {
  padding: 80px 0;

  .title-box {
    text-align: center;
  }

  .heading {
    text-align: center;
  }
}
