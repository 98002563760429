$font: "Open Sans", sans-serif;

// Color:
$primary: #75b1d2;
$secondary: #052944;

$black: #000000;
$black-1: #231f20;
$gray: #677278;
$gray-1: #fbfbfb;
$gray-2: #c4c4c4;
$gray-3: #e1e3e4;
$gray-4: #dcdcdc;
$gray-5: #b5b5b5;
$gray-6: #e1e3e4;

$white: #ffffff;

$red: #c90000;
$orange: #f06b35;

// Breakpoints
$breakpoint-xs: 480px;
$breakpoint-sm: 768px;
$breakpoint-md: 1024px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1366px;
$breakpoint-xxl: 1600px;
