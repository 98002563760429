@import "../variables";

.btn {
  display: inline-block;
  font-weight: 700;
  color: $white;
  text-align: center;
  min-width: 114px;
  padding: 5px;
  transition: all 0.3s ease;
  cursor: pointer;
  border-radius: 4px;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 1.5;

  &:hover {
    transition: all 0.3s ease;
  }

  &-md {
    font-size: 18px;
    line-height: 38px;
  }

  &-lg {
    font-size: 18px;
    line-height: 44px;
  }

  &.btn-primary {
    background-color: $primary;

    &:hover {
      background-color: #519ec8;
      color: $white;
    }

    &.btn-outline {
      border: 1px solid $primary;
      background-color: transparent;
      color: $primary;

      &:hover {
        background-color: $primary;
        color: $white;
      }
    }
  }

  &.btn-secondary {
    background-color: $secondary;

    &:hover {
      background-color: #001d33;
      box-shadow: 0px 4px 4px rgba($black, 0.25);
    }

    &.btn-outline {
      border: 1px solid $secondary;
      background-color: transparent;
      color: $secondary;

      &:hover {
        background-color: $secondary;
        color: $white;
      }
    }
  }

  &.btn-white {
    &.btn-outline {
      border: 1px solid $white;
      background-color: transparent;
      color: $white;

      &:hover {
        background-color: $white;
        color: $primary;
      }
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
}
