.auth-page {
  padding: 60px 0;
  background-color: $gray-1;

  @media (min-width: $breakpoint-sm) {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  @media (min-width: $breakpoint-lg) {
    background-size: auto;
  }

  .heading {
    margin-bottom: 20px;

    @media (min-width: $breakpoint-sm) {
      margin-bottom: 40px;
    }
  }

  .subtitle {
    font-size: 16px;
    color: $gray;
    margin-bottom: 20px;
    text-align: center;

    @media (min-width: $breakpoint-sm) {
      text-align: left;
      font-size: 18px;
      margin-bottom: 40px;
    }
  }

  .button-box {
    padding: 20px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin: 0 auto;

    @media (min-width: $breakpoint-sm) {
      display: block;
      margin: 0;
      max-width: none;
      padding: 20px 0 60px;
      text-align: left;
    }

    .btn {
      order: 2;

      @media (min-width: $breakpoint-sm) {
        margin-right: 20px;
        min-width: 228px;
      }
    }
  }

  .auth-text {
    margin-bottom: 10px;

    @media (min-width: $breakpoint-sm) {
      margin: 0;
    }
  }

  .form-link {
    color: $secondary;
    font-weight: 700;
    font-size: 18px;
    display: inline-block;
    text-decoration: underline;

    &:hover {
      color: $primary;
    }
  }

  .form-wrapp {
    max-width: 400px;
    margin: 0 auto;

    @media (min-width: $breakpoint-sm) {
      margin: 0;
    }

    @media (min-width: $breakpoint-md) {
      max-width: 476px;
    }
  }

  .form-text {
    color: $gray;
    font-size: 14px;

    @media (min-width: $breakpoint-sm) {
      font-size: 18px;
    }

    a {
      color: $gray;
      text-decoration: underline;

      &:hover {
        color: $primary;
      }
    }
  }
}

.login-page {
  @media (min-width: $breakpoint-sm) {
    background-image: url("../../../src/assets/images/login-bg.png");
  }

  @media (min-width: $breakpoint-md) {
    padding: 120px 0 250px;
  }
}

.forgot-page {
  .subtitle {
    max-width: 400px;
    @media (min-width: $breakpoint-lg) {
      max-width: 610px;
    }
  }

  @media (min-width: $breakpoint-sm) {
    background-image: url("../../../src/assets/images/forgot-bg.png");
  }

  @media (min-width: $breakpoint-md) {
    padding: 190px 0 250px;
  }

  @media (min-width: $breakpoint-lg) {
    min-height: 900px;
  }
}

.register-page {
  @media (min-width: $breakpoint-md) {
    padding: 120px 0 250px;
  }

  .form-grid {
    max-width: 400px;
    margin: 0 auto;

    @media (min-width: $breakpoint-sm) {
      margin: 0;
      max-width: 972px;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-column-gap: 20px;
    }
  }

  .form-link-capture {
    color: $gray;
  }
}
