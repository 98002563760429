@import '../variables';

* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

#root {
  overflow-x: hidden;
}

html,
body {
  font-size: 16px;
  line-height: 1.2;
  font-weight: 400;
  color: $secondary;
}

body {
  background-color: $white;
  font-family: $font;
  min-width: 320px;
}

p {
  margin: 0 0 10px;
}

a {
  text-decoration: none;
  transition: all 0.3s ease;

  &:hover {
    transition: all 0.3s ease;
    color: $primary;
  }
}

main {
  font-size: 14px;
  line-height: 19px;
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;

  @media (min-width: $breakpoint-sm) {
    max-width: 768px;
  }

  @media (min-width: $breakpoint-md) {
    max-width: 1024px;
  }

  @media (min-width: $breakpoint-lg) {
    max-width: 1170px;
  }
}

.title-box {
  margin-bottom: 30px;
  text-align: center;

  @media (min-width: $breakpoint-sm) {
    margin-bottom: 10px;
    text-align: left;
  }
}

.title {
  letter-spacing: 0.395em;
  font-weight: 800;
  text-transform: uppercase;
  color: rgba($secondary, 0.05);
  position: relative;
  display: inline-block;
  font-size: 24px;

  @media (min-width: $breakpoint-sm) {
    font-size: 35px;
  }

  &-accent {
    letter-spacing: 0.8em;
    text-transform: uppercase;
    color: $secondary;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    max-width: 250px;
    width: 100%;
    text-align: center;

    @media (min-width: $breakpoint-sm) {
      max-width: none;
      white-space: nowrap;
      font-size: 18px;
    }
  }
}

.heading {
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: $secondary;
  text-align: center;
  margin-bottom: 40px;
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;

  @media (min-width: $breakpoint-sm) {
    margin-bottom: 80px;
    font-size: 48px;
    line-height: 60px;
    text-align: left;
  }

  &-accent {
    color: $primary;
  }
}
