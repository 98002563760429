@import "../variables";

.form-control {
  width: 100%;
  border: 0.2px solid rgba($gray-2, 0.15);
  background-color: rgba($gray-2, 0.15);
  font-size: 14px;
  outline: none;
  border-radius: 4px;
  line-height: 30px;
  padding: 5px 16px;

  &-md {
    padding: 5px 20px;
    line-height: 40px;
  }

  &::placeholder {
    color: $gray;
  }

  &:focus {
    border-color: $primary;
  }

  &.error {
    border-color: $orange;
  }

  &.darken {
    background-color: #0a3454;
    border-color: #8e8e8e;
    color: $white;

    &::placeholder {
      color: $white;
    }

    &:focus {
      border-color: $primary;
    }
  }
}

.input-box {
  margin-bottom: 20px;

  &.error {
    margin-bottom: 0;

    .form-control {
      border-color: $orange;
    }

    span {
      color: $orange;
      line-height: 20px;
      display: inline-block;
      font-size: 14px;
    }
  }

  &.input-success {
    span {
      color: $gray;
      line-height: 20px;
      display: inline-block;
      font-size: 14px;
    }
  }
}

body {
  .ant-input-affix-wrapper {
    padding: 0;

    .ant-input-suffix {
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      right: 11px;
    }
  }

  .ant-input.ant-input-lg {
    padding: 7px 30px 7px 20px;
    line-height: 46px;
  }

  .input-box {
    .ant-input,
    .ant-input-affix-wrapper {
      background-color: transparent;
      border-radius: 4px;
      color: $gray;

      &:before {
        display: none;
      }

      &:hover,
      &:focus,
      &.ant-input-affix-wrapper-focused {
        background-color: transparent;
        border-color: $primary;
        box-shadow: none;
      }

      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 50px $white inset !important;
        font-family: $font;
        font-size: 16px;
        color: $gray;

        &::first-line {
          font-family: $font;
          font-size: 16px;
          color: $gray;
        }

        &:hover,
        &:focus,
        &:active {
          -webkit-box-shadow: 0 0 0 50px $white inset !important;
          font-family: $font;
          font-size: 16px;
          color: $gray;
        }
      }
    }

    &.error {
      .ant-input,
      .ant-input-affix-wrapper {
        border-color: $orange;
      }
    }
  }

  .ant-input:focus,
  .ant-input-focused {
    border-color: $primary;
    box-shadow: none;
  }
}
