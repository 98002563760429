@import "../variables";

.our-section {
  position: relative;
  padding: 35px 0 30px;

  @media (min-width: $breakpoint-sm) {
    padding: 80px 0;
  }

  @media (min-width: $breakpoint-md) {
    padding: 140px 0 190px;
  }

  .title-box {
    text-align: center;
  }

  .heading {
    text-align: center;
  }

  .container {
    position: relative;
  }

  .line-1 {
    left: 50%;
    margin-left: -224px;
    height: 321px;
    top: 100%;
  }
}

.our-row {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: repeat(3, minmax(0, 1fr));
  grid-gap: 20px;

  @media (min-width: $breakpoint-sm) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  @media (min-width: $breakpoint-md) {
    grid-gap: 0;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: minmax(0, 1fr);
  }
}

.our-col {
  display: grid;
  grid-template-columns: minmax(0, 345px);
  grid-template-rows: minmax(0, 1fr);
  justify-content: center;

  @media (min-width: $breakpoint-md) {
    grid-template-columns: minmax(0, 1fr);
  }

  &--main {
    @media (min-width: $breakpoint-md) {
      position: relative;
      margin: 0 -10px;
      margin-top: 34px;
      margin-bottom: -34px;
    }
  }

  &:last-child {
    @media (min-width: $breakpoint-sm) {
      grid-column: span 2;
    }

    @media (min-width: $breakpoint-md) {
      grid-column: auto;
    }
  }
}

.our-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  box-shadow: -5px -5px 20px $white, 5px 5px 20px rgba(174, 174, 192, 0.5);
  border-radius: 4px;
  background-color: $white;
  padding: 40px 30px;

  @media (min-width: $breakpoint-sm) {
    padding: 40px 15px;
  }

  &__text {
    margin-bottom: 35px;
  }

  &__tag {
    color: $gray;
    margin: 0 5px 45px;
    text-align: center;
    font-size: 11px;
    padding-bottom: 20px;
    border-bottom: 1px solid $gray-3;

    strong {
      font-size: 24px;
      color: $secondary;
    }
  }

  &__title {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 10px;
    color: $primary;
    text-align: center;

    @media (min-width: $breakpoint-sm) {
    }
  }

  &__list {
    margin-bottom: 20px;

    @media (min-width: $breakpoint-sm) {
      padding-left: 45px;
    }

    li {
      margin-bottom: 8px;
      display: flex;
      color: $gray;
    }

    a {
      display: flex;
    }

    i {
      width: 18px;
      height: 18px;
      font-size: 18px;
      flex-shrink: 0;
      color: $primary;

      &.icon-plus {
        color: $secondary;
      }
    }

    span {
      flex-shrink: 1 1 0;
      padding-left: 10px;
    }
  }

  &__link {
    a {
      color: $secondary;
      font-size: 14px;
      line-height: 19px;

      &:hover {
        color: $primary;
      }
    }
  }

  &__capture {
    color: $gray;
    text-align: center;
    margin-bottom: 20px;
  }

  &__btn {
    text-align: center;

    .btn {
      width: 100%;
      max-width: 260px;
      text-transform: uppercase;
      line-height: 22px;
      padding: 15px 5px;
    }
  }
}
