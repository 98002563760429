@import "../variables";

.hero-section {
  position: relative;
  padding: 60px 0 40px;

  @media (min-width: $breakpoint-sm) {
    padding: 60px 0 140px;
  }

  @media (min-width: $breakpoint-md) {
    padding: 188px 0 40px;
    margin-bottom: 80px;
  }

  @media (min-width: $breakpoint-lg) {
    background-size: 366px;
    background-position: 90% bottom;
  }

  @media (min-width: $breakpoint-xxl) {
    background-position: 78% bottom;
  }

  .title-box {
    text-align: center;
    position: relative;
    line-height: normal;

    @media (min-width: $breakpoint-sm) {
      text-align: left;
    }
  }

  .main-line {
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    height: 50px;

    @media (min-width: $breakpoint-sm) {
      margin-left: -230px;
    }

    @media (min-width: $breakpoint-md) {
      margin-left: -360px;
      height: 175px;
    }

    @media (min-width: $breakpoint-lg) {
      margin-left: -430px;
    }
  }

  .line-1 {
    left: 0;
    right: 50%;
    margin-right: 282px;
    top: 962px;

    &:after {
      display: none;
    }
  }

  .line-2 {
    left: 50%;
    margin-left: -282px;
    height: 44px;
    top: 962px;
  }
}

.hero-title {
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  margin: 0 auto 40px;
  max-width: 280px;
  text-transform: uppercase;
  font-weight: 700;

  @media (min-width: $breakpoint-sm) {
    font-size: 38px;
    line-height: 48px;
    max-width: 854px;
    text-align: left;
  }

  @media (min-width: $breakpoint-md) {
    font-size: 48px;
    line-height: 60px;
    margin: 0 0 40px;
  }

  &--accent {
    display: inline-flex;
    align-items: flex-start;
    justify-content: center;
    color: $primary;
    font-size: 24px;
    line-height: 18px;
    font-weight: 800;
    padding-top: 5px;

    @media (min-width: $breakpoint-sm) {
      padding-top: 10px;
      justify-content: flex-start;
      font-size: 38px;
      line-height: 38px;
    }

    @media (min-width: $breakpoint-md) {
      font-size: 48px;
      line-height: 48px;
    }

    i {
      margin-right: -3px;

      @media (min-width: $breakpoint-sm) {
        margin: 6px -6px 0 0;
      }
    }
  }
}

.hero-row {
  @media (min-width: $breakpoint-md) {
    display: flex;
    margin: 0 15px;
  }
}

.hero-col {
  @media (min-width: $breakpoint-md) {
    padding: 0 15px;
  }
}

.hero-col-2 {
  flex-shrink: 0;
  width: 100%;
  max-width: 565px;
}

.hero-video {
  &:before {
    content: "";
    background-image: url("../../../src/assets/images/hero-video-bg.svg");
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: contain;
    width: 73%;
    height: 100px;
    position: absolute;
    left: 0;
    bottom: calc(100% + 18px);
  }

  &:after {
    content: "";
    background-image: url("../../../src/assets/images/hero-video-bg-2.svg");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: contain;
    height: 570px;
    position: absolute;
    top: -37%;
    left: 18%;
    z-index: -1;
    width: 147%;
    max-width: 724px;
  }

  &-box {
    border: 20px solid $secondary;
    border-radius: 20px;
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    margin-top: 154px;

    &:before {
      content: "";
      position: absolute;
      top: calc(100% + 20px);
      left: 50%;
      transform: translateX(-50%);
      display: block;
      height: 0;
      width: 140px;
      border-bottom: 50px solid $secondary;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
    }

    &:after {
      content: "";
      position: absolute;
      top: calc(100% + 50px);
      left: 50%;
      transform: translateX(-50%);
      display: block;
      width: 216px;
      height: 24px;
      background-color: $secondary;
      border-radius: 10px 10px 4px 4px;
      box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.25) inset;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-width: 0;
      outline-width: 0;
    }
  }
}

.hero-list {
  margin-bottom: 40px;

  li {
    color: $gray;
    font-size: 11px;
    line-height: 14px;
    text-align: center;

    @media (min-width: $breakpoint-sm) {
      display: flex;
      align-items: flex-start;
      font-size: 16px;
      line-height: 18px;
      text-align: left;
    }

    @media (min-width: $breakpoint-md) {
      font-size: 18px;
      line-height: 25px;
    }

    &:before {
      @media (min-width: $breakpoint-sm) {
        content: "";
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: $secondary;
        margin: 7px 20px 0 0;
        flex-shrink: 0;
      }

      @media (min-width: $breakpoint-md) {
        margin-top: 11px;
      }
    }
  }
}

.hero-link-box {
  text-align: center;
  margin-bottom: 10px;

  @media (min-width: $breakpoint-sm) {
    text-align: left;
  }
}

.hero-link {
  text-transform: uppercase;
  color: $secondary;
  font-weight: 600;
  font-size: 14px;
  position: relative;

  @media (min-width: $breakpoint-md) {
    font-size: 18px;
  }

  &:hover {
    color: $primary;
  }

  &:after {
    content: "";
    display: block;
    width: 94px;
    height: 136px;
    position: absolute;
    bottom: -40px;
    right: -60px;
    background-image: url("../../../src/assets/images/hero-bg-mobile.svg");
    background-repeat: no-repeat;
    background-size: contain;

    @media (min-width: $breakpoint-sm) {
      display: none;
    }
  }

  i {
    padding-left: 5px;
    font-size: 12px;

    @media (min-width: $breakpoint-md) {
      font-size: 14px;
    }
  }
}

.hero-capture-box {
  display: flex;
  align-items: flex-end;
  justify-content: center;

  @media (min-width: $breakpoint-sm) {
    justify-content: flex-start;
  }

  span {
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1;
    font-size: 7px;
    padding-right: 5px;
  }
}

.hero-line {
  width: 1px;
  height: 11px;
  background-color: rgba(103, 114, 120, 0.75);
  margin: 0 7px 0 6px;
}
