@import "../variables";

body {
  .ant-collapse {
    > .ant-collapse-item {
      &.ant-collapse-item-active {
        > .ant-collapse-header {
          .ant-collapse-arrow {
            &:before {
              content: "\e912";
              opacity: 1;
            }
          }
        }
      }

      &.ant-collapse-no-arrow {
        > .ant-collapse-header {
          padding-left: 28px;
        }
      }

      > .ant-collapse-header {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 18px;
        color: $secondary;
        border-bottom: 1px solid rgba($gray, 0.1);
        padding-left: 28px;

        &:before {
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: $secondary;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          transition: all 0.3s;
        }

        &:hover {
          color: $primary;

          &:before {
            background-color: $primary;
            transition: all 0.3s;
          }
        }

        .ant-collapse-arrow {
          top: 50%;
          transform: translateY(-50%);
          padding: 0;

          svg {
            display: none;
          }

          &:before {
            content: "\e918";
            font-family: "icomoon";
            display: block;
            font-size: 18px;
            opacity: 0.2;
            color: $gray;
          }
        }
      }

      > .ant-collapse-content {
        > .ant-collapse-content-box {
          padding-left: 28px;
          max-width: 850px;
          color: $gray;
          font-size: 14px;
          line-height: 19px;

          ol,
          li {
            list-style: lower-alpha;
          }
        }
      }
    }
  }
}
